<template>
  <div class="hero-rank">
    <img ref="rank" :src="src" :width="width || 32" :height="height || 32" />
    <span v-html="rankName"></span>
  </div>
</template>

<script>
import { getRankByMmr, CALLIBRATION_GAMES, getHeroRankText } from '@/constants'

export default {
  name: 'KoPPlayerRank',

  props: {
    seasonGames: Number,
    mmr: Number,
    width: String,
    height: String,
  },

  computed: {
    src() {
      if (this.seasonGames < CALLIBRATION_GAMES) {
        return `/img/ranks/unranked.png`
      }

      const rank = getRankByMmr(this.mmr)

      return `/ranks/${rank}.png`
    },
    rankName() {
      return getHeroRankText(this.mmr, this.seasonGames)
    },
  },
}
</script>

<style scoped>
img {
  image-rendering: pixelated;
}

.hero-rank {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
