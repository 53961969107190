<template>
  <div>
    <match v-for="match in matches" :key="match.id" :match="match"></match>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import HeroIcon from '@/components/hero_icon.vue'
import utils from '@/utils'
import match from '@/components/match.vue'

export default {
  name: 'KopLeaderboard',
  components: {
    HeroIcon,
    match,
  },

  data() {
    return {
      matches: [],
      utils,
    }
  },

  async mounted() {
    this.matches = await this.utils.getMatches(50, [
      this.utils.matchesAdditions.winner,
      this.utils.matchesAdditions.heroId,
      this.utils.matchesAdditions.gameTime,
      this.utils.matchesAdditions.team,
      this.utils.matchesAdditions.fancyPoints,
      this.utils.matchesAdditions.kills,
      this.utils.matchesAdditions.deaths,
      this.utils.matchesAdditions.assists,
      this.utils.matchesAdditions.networth,
      this.utils.matchesAdditions.lasthits,
      this.utils.matchesAdditions.towerDamage,
      this.utils.matchesAdditions.heroDamage,
      this.utils.matchesAdditions.damageTaken,
      this.utils.matchesAdditions.playedAtUtc,
      this.utils.matchesAdditions.items,
      this.utils.matchesAdditions.talents,
    ])
  },
}
</script>

<style scoped>
th {
  background-color: rgba(2, 24, 33, 0.7);
  border-bottom: 2px solid rgba(22, 44, 53, 1);
}

tr {
  color: white !important;
  text-align: center;
}
tr:hover {
  background-color: rgba(2, 24, 33, 0.7);
  filter: brightness(1);
}
td {
  background-color: rgba(2, 24, 33, 0.7);
  filter: brightness(0.8);
  border-bottom: 1px solid rgba(22, 44, 53, 1);
}

.red-team-text {
  color: rgb(238, 75, 43);
}
.blue-team-text {
  color: rgb(0, 150, 255);
}

.clickable {
  font-weight: bold;
  cursor: pointer;
}
</style>
