<template>
  <img ref="heroImg" :src="src" :width="width || 32" :height="height || 32" @click.self="onClick()" />
</template>

<script>
import api_communication from '@/api_communication'
import utils from '@/utils'

export default {
  name: 'KopHeroIcon',

  props: {
    hero_name: [String, Number],
    width: String,
    height: String,
  },

  data() {
    return {
      api_communication,
    }
  },

  computed: {
    src() {
      if (isNaN(this.hero_name)) {
        // Converts the hero name to the hero id
        return this.api_communication.getHeroAssetURL(utils.getHeroIdByName(this.hero_name))
      }
      return this.api_communication.getHeroAssetURL(this.hero_name)
    },
  },

  methods: {
    onClick() {
      this.$router.push({ name: 'Hero', params: { name: this.hero_name } })
    },
  },

  // Stop receiving events from parent
}
</script>

<style scoped></style>
