<template>
  <div>
    <div class="filter">
      <input
        ref="inputText"
        type="text"
        class="form-control"
        aria-label="Text input"
        placeholder="Filter Version"
        @keyup.enter="filterByVersion"
      />
      <button id="button-addon2" class="btn btn-primary br-2" type="button" @click.self="filterByVersion">
        Search
      </button>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col" class="bg-secondary"></th>
          <th scope="col" class="t-name bg-secondary" @click="sortBy('name')">Name</th>
          <th scope="col" class="t-games bg-secondary" @click="sortBy('games')">Games Played</th>
          <th scope="col" class="t-winrate bg-secondary" @click="sortBy('winrate')">Avg. Win Rate</th>
          <th scope="col" class="t-kda bg-secondary" @click="sortBy('kda')">Avg. KDA</th>
          <th scope="col" class="t-pickrate bg-secondary" @click="sortBy('pickrate')">Pick Rate</th>
          <th scope="col" class="t-banrate bg-secondary" @click="sortBy('banrate')">Ban Rate</th>
          <th scope="col" class="t-fancyPoints bg-secondary" @click="sortBy('fancyPoints')">Avg. Fancy Points</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(sortedHeroDetails, heroName, index) in sortedHeroDetails" :key="index">
          <td><HeroIcon :hero_name="heroName" width="48" height="48"></HeroIcon></td>
          <td>{{ heroName }}</td>
          <td>{{ sortedHeroDetails.games }}</td>
          <td>{{ sortedHeroDetails.winrate }}</td>
          <td>{{ sortedHeroDetails.kda }}</td>
          <td>{{ sortedHeroDetails.pickrate }}</td>
          <td>{{ sortedHeroDetails.banrate }}</td>
          <td>{{ sortedHeroDetails.fancyPoints }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import HeroIcon from '@/components/hero_icon.vue'
import utils from '@/utils'
// import api_communication from '@/api_communication';

export default {
  name: 'KopLeaderboard',
  components: {
    HeroIcon,
  },

  data() {
    return {
      heroesDetails: {},
      sortedHeroDetails: {},
      utils,
      sortKey: 'games',
      sortDir: -1,
      isLoading: false,
    }
  },

  async mounted() {
    await this.fetchData()
    this.sortBy('games')
  },

  methods: {
    async fetchData(version = null) {
      if (this.isLoading) {
        return
      }

      this.isLoading = true
      const matches = await this.utils.getMatches(
        1000,
        [
          this.utils.matchesAdditions.winner,
          this.utils.matchesAdditions.heroId,
          this.utils.matchesAdditions.gameTime,
          this.utils.matchesAdditions.team,
          this.utils.matchesAdditions.kills,
          this.utils.matchesAdditions.deaths,
          this.utils.matchesAdditions.assists,
          this.utils.matchesAdditions.bans,
          this.utils.matchesAdditions.version,
          this.utils.matchesAdditions.fancyPoints,
        ],
        null,
        version
      )

      this.utils.heroesNames.forEach((hero) => {
        if (hero == 'Dummy') {
          return
        }
        this.heroesDetails[hero] = {
          games: 0,
          wins: 0,
          losses: 0,

          kills: 0,
          deaths: 0,
          assists: 0,
          kda: 0,

          bans: 0,
          winrate: 0,
          pickrate: 0,
          banrate: 0,
          fancyPoints: 0,
        }
      })

      matches.forEach((match) => {
        if (Array.isArray(match.bans)) {
          match.bans?.forEach((ban) => {
            this.heroesDetails[ban].bans++
          })
        }
        this.utils.getAllPlayers(match).forEach((player) => {
          if (player.won) {
            this.heroesDetails[player.heroName].wins++
          } else {
            try {
              this.heroesDetails[player.heroName].losses++
            } catch (e) {
              console.log(this.heroesDetails, player)
            }
          }
          this.heroesDetails[player.heroName].kills += player.kills
          this.heroesDetails[player.heroName].deaths += player.deaths
          this.heroesDetails[player.heroName].assists += player.assists
          this.heroesDetails[player.heroName].fancyPoints += player.fancyPoints
        })
      })
      for (let hero in this.heroesDetails) {
        this.heroesDetails[hero].games = this.heroesDetails[hero].wins + this.heroesDetails[hero].losses
        this.heroesDetails[hero].winrate = (
          (this.heroesDetails[hero].wins / this.heroesDetails[hero].games) *
          100
        ).toFixed(2)
        this.heroesDetails[hero].pickrate = (this.heroesDetails[hero].games / matches.length).toFixed(2)
        this.heroesDetails[hero].banrate = (this.heroesDetails[hero].games / matches.length).toFixed(2)

        this.heroesDetails[hero].kills = parseInt(
          (this.heroesDetails[hero].kills / this.heroesDetails[hero].games).toFixed(2)
        )
        this.heroesDetails[hero].deaths = parseInt(
          (this.heroesDetails[hero].deaths / this.heroesDetails[hero].games).toFixed(2)
        )
        this.heroesDetails[hero].assists = parseInt(
          (this.heroesDetails[hero].assists / this.heroesDetails[hero].games).toFixed()
        )

        this.heroesDetails[hero].kda =
          +this.heroesDetails[hero].kills +
          '/' +
          this.heroesDetails[hero].assists +
          '/' +
          this.heroesDetails[hero].deaths +
          ' - ' +
          (
            (this.heroesDetails[hero].kills + this.heroesDetails[hero].assists) /
            this.heroesDetails[hero].deaths
          ).toFixed(2)

        console.log(
          this.heroesDetails[hero].kills,
          this.heroesDetails[hero].assists,
          this.heroesDetails[hero].deaths,
          this.heroesDetails[hero].kda
        )

        this.heroesDetails[hero].fancyPoints = (
          this.heroesDetails[hero].fancyPoints / this.heroesDetails[hero].games
        ).toFixed(2)
      }

      this.isLoading = false
    },
    onSearchByVersion() {
      const is_numbers = /^\d+$/.test(this.$refs.inputText.value)
      if (is_numbers) {
        this.$router.push({ name: 'Match', params: { id: this.$refs.inputText.value } })
      } else {
        this.$router.push({ name: 'Player', params: { name: this.$refs.inputText.value } })
      }
    },
    async filterByVersion() {
      const version = this.$refs.inputText.value
      if (!version) {
        return
      }
      this.heroesDetails = {}
      this.sortedHeroDetails = {}
      await this.fetchData(version)
      this.sortBy('games', 1)
    },
    sortBy(key) {
      // remove active
      document.querySelectorAll('th').forEach((th) => th.classList.remove('active'))

      if (this.sortKey === key) {
        this.sortDir *= -1
      } else {
        this.sortKey = key
        this.sortDir = -1
      }

      document.querySelectorAll('th').forEach((th) => th.classList.remove('asc', 'desc'))
      document.querySelector(`.t-${key}`)?.classList.add('active')

      const th = document.querySelector(`.t-${key}`)
      th?.classList.add(this.sortDir === 1 ? 'asc' : 'desc')

      const sortedEntries = Object.entries(this.heroesDetails).sort((a, b) => {
        const aValue = Number(a[1][key])
        const bValue = Number(b[1][key])
        return (aValue - bValue) * this.sortDir
      })

      this.sortedHeroDetails = Object.fromEntries(sortedEntries)
    },
  },
}
</script>

<style scoped>
th {
  border-bottom: 2px solid rgba(22, 44, 53, 1);
  cursor: pointer;
  user-select: none;
}

th.asc::after {
  content: ' ▲';
}

th.desc::after {
  content: ' ▼';
}

tr {
  text-align: center;

  color: white !important;
}
tr:hover {
  background-color: rgba(2, 24, 33, 0.7);
  filter: brightness(1);
}

td {
  background-color: var(--primary-color);
  filter: brightness(0.8);
  border-bottom: 1px solid rgba(22, 44, 53, 1);
}

.active {
  background-color: rgba(2, 24, 33, 0.7);
  filter: brightness(1.25);
  color: orange;
}

.gold-text:before,
.gold-text:after {
  content: ' 👑 ';
}
.silver-text:before,
.silver-text:after {
  content: ' 🥈 ';
}
.bronze-text:before,
.bronze-text:after {
  content: ' 🥉 ';
}

.filter {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  gap: 12px;
  width: 40%;
  float: right;

  > input {
    flex: 3;
  }
  > button {
    flex: 1;
  }
}
</style>
