import axios from "axios"
import { decode } from "msgpackr/unpack"
import { Buffer } from "buffer"

const debug = 0

const domain = debug && "http://localhost:2000" || "https://eu.leagueofpixels.eu"

const API_URL = `${domain}/api/public`

const API_ENDPOINTS = {
  heroes: "heroes",
  items: "items",
  player: "player",
  match: "match",
  matches: "matches",
  leaderboard: "leaderboard",
}


const getHeroAssetURL = (heroID) => { // Allow for hero_name to be passed in and converted to heroID
  return `https://leagueofpixels.eu/assets2/creatures/heroes/${heroID}/card.png`
}

const getItemAssetURL = (itemName) => {
  return `https://leagueofpixels.eu/assets2/items/itemUsed/${itemName}.png`
}


const response_type_replay_buffer = { responseType: "arraybuffer" }

const getHeroesNames = async () => {
  const response = await axios.get(`${API_URL}/${API_ENDPOINTS.heroes}`, response_type_replay_buffer)
  const decoded = decode(Buffer.from(response.data))
  return decoded
}

const getItems = async () => {
  const response = await axios.get(`${API_URL}/${API_ENDPOINTS.items}`, response_type_replay_buffer)
  const decoded = decode(Buffer.from(response.data))
  return decoded
}

const getPlayer = async (player_ID_or_name, additions=[]) => {
  let identifier = `id=${player_ID_or_name}`
  if (isNaN(player_ID_or_name)) {
    identifier = `name=${player_ID_or_name}`
  }

  const response = await axios.get(`${API_URL}/${API_ENDPOINTS.player}?${identifier}&additions=${additions.join(",")}`, response_type_replay_buffer)
  if (!response.data.byteLength) {
    return {}
  }
  const decoded = decode(Buffer.from(response.data))
  return decoded
}

const getMatch = async (matchID) => {
  const response = await axios.get(`${API_URL}/${API_ENDPOINTS.match}?id=${matchID}`, response_type_replay_buffer)
  const decoded = decode(Buffer.from(response.data))
  return decoded
}

const getMatches = async (numberOfMatches, additions=[], playerID=null, version=null) => {
  const getQueryParams = (playerID && `player=${playerID}` || "") + (version && `version=${version}` || "")  + `&take=${numberOfMatches}`
  const response = await axios.get(`${API_URL}/${API_ENDPOINTS.matches}?${getQueryParams}&additions=${additions.join(",")}`, response_type_replay_buffer)
  const decoded = decode(Buffer.from(response.data))
  return decoded
}

const getPlayerIDByName = async (playerName) => {
  const response = await axios.get(`${API_URL}/${API_ENDPOINTS.player}?name=${playerName}`, response_type_replay_buffer)
  const decoded = decode(Buffer.from(response.data))
  return decoded.id
}

const getLeaderboard = async (heroStats=true) => {
  heroStats = heroStats && 1 || 0
  const response = await axios.get(`${API_URL}/${API_ENDPOINTS.leaderboard}?herostats=${heroStats}`, response_type_replay_buffer)
  const decoded = decode(Buffer.from(response.data))
  return decoded
}

export default {
  getHeroAssetURL,
  getItemAssetURL,
  getHeroesNames,
  getItems,
  getPlayer,
  getMatch,
  getMatches,
  getLeaderboard,
  getPlayerIDByName,
}
