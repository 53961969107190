<template>
  <div class="container-fluid game-card">
    <div class="row mb-3">
      <div class="col-12 p-0">
        <div class="bg-primary">
          <div
            class="card-body d-flex align-items-center bg-primary ps-2 pe-2 pt-0 pb-0"
            style="border: 1px solid rgba(38, 70, 83, 0.3) !important"
          >
            <div class="d-flex align-items-center flex-grow-1">
              <div class="position-relative me-3" style="width: 64px; height: 64px">
                <img :src="player.picture" alt="" class="img-fluid rounded-circle" />
              </div>
              <div>
                <h1 class="h3 mb-0 text-white">{{ player.name }}</h1>
                <PlayerLevel :xp="player.AccountStats?.experiences" />
              </div>
            </div>
            <div class="mt-1">
              <PlayerRank
                :height="48"
                :width="48"
                :mmr="player.AccountStats?.mmr"
                :season-games="player.AccountStats?.seasonGames"
              />
              <span class="badge d-block" :class="getRankColor(player.AccountStats?.mmr)"
                >MMR: {{ player.AccountStats?.mmr || 'N/A' }}</span
              >
            </div>
            <div class="ms-auto text-center" style="padding: 0 15px">
              <div class="">
                <p class="mb-0">
                  <span class="badge bg-success me-1">{{ wins }}</span>
                  <span class="badge bg-danger">{{ player.AccountStats?.gamesLost }}</span>
                </p>
                <small class="text-muted">RECORD</small>
              </div>
              <div>
                <p class="mb-0" v-html="getWinrate()"></p>
                <small class="text-muted">WINRATE</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 mb-4 mb-lg-0 p-0">
        <FancyPointsGraph :matches="matches" />
        <div class="bg-primary">
          <div class="card-header bg-secondary text-white text-center">
            <h3 class="card-title mb-0 p-2" style="border-bottom: 1px solid var(--primary-border-color)">
              Most Played Heroes
            </h3>
          </div>
          <ul v-if="sortedHeroStats.length" class="list-group list-group-flush">
            <li v-for="(heroData, index) of sortedHeroStats" :key="heroData.heroId" class="list-group-item bg-primary">
              <mostPlayedHero :hero-data="heroData" :index="index"></mostPlayedHero>
            </li>
          </ul>
          <p v-else class="text-center text-muted">No heroes played yet</p>
        </div>
      </div>
      <div class="col-lg-8 ps-3 pe-0">
        <match v-for="match in matches" :key="match.id" :match="match"></match>
      </div>
    </div>
  </div>
</template>

<script>
// import partial_match from '@/components/partial_match.vue';
import api_communication from '@/api_communication'
import mostPlayedHero from '@/components/player_most_played.vue'
import PlayerRank from '@/components/player_rank.vue'
import PlayerLevel from '@/components/player_level.vue'
import { getRankColor } from '@/constants/Ranks'
import utils from '@/utils'
import match from '@/components/match.vue'
import FancyPointsGraph from '@/components/fancy_points_graph.vue'

export default {
  name: 'KopMatchHistory',
  components: {
    // partial_match,
    mostPlayedHero,
    PlayerRank,
    PlayerLevel,
    match,
    FancyPointsGraph,
  },
  data() {
    return {
      api_communication,
      getRankColor,
      player: {},
      utils,
      matches: [],
    }
  },

  computed: {
    wins() {
      return (this.player.AccountStats?.gamesTotal ?? 0) - (this.player.AccountStats?.gamesLost ?? 0)
    },
    sortedHeroStats() {
      const heroStats = this.player?.AccountHeroStats || []
      return [...heroStats].sort((a, b) => {
        // Sort by seasonGamesTotal in descending order
        return b.seasonGamesTotal - a.seasonGamesTotal
      })
    },
  },

  async mounted() {
    const allAdditions = [
      'id',
      'mmr',
      'experiences',
      'seasonGamesLost',
      'seasonGamesTotal',
      'rankedGamesLost',
      'rankedGamesTotal',
      'gamesLost',
      'gamesTotal',
      'flags',
      'name',
      'picture',
      'created',
      'AccountHeroStats',
    ]
    const playerName = this.$route.params.name.split('-')[0]
    const player = await api_communication.getPlayer(playerName, allAdditions)
    if (!player.length) {
      return
    }
    this.player = player[0]

    this.matches = await this.utils.getMatches(
      25,
      [
        this.utils.matchesAdditions.winner,
        this.utils.matchesAdditions.heroId,
        this.utils.matchesAdditions.gameTime,
        this.utils.matchesAdditions.team,
        this.utils.matchesAdditions.fancyPoints,
        this.utils.matchesAdditions.kills,
        this.utils.matchesAdditions.deaths,
        this.utils.matchesAdditions.assists,
        this.utils.matchesAdditions.networth,
        this.utils.matchesAdditions.lasthits,
        this.utils.matchesAdditions.towerDamage,
        this.utils.matchesAdditions.heroDamage,
        this.utils.matchesAdditions.damageTaken,
        this.utils.matchesAdditions.playedAtUtc,
        this.utils.matchesAdditions.items,
        this.utils.matchesAdditions.talents,
      ],
      this.player.id
    )
  },

  methods: {
    getWinrate() {
      if (!this.player?.AccountStats) return

      const { gamesTotal, gamesLost } = this.player.AccountStats
      const winrate = (((gamesTotal - gamesLost) / gamesTotal) * 100).toFixed(2)
      return `<span style="color: ${winrate >= 50 ? 'lime' : 'red'}"> ${winrate}% </span>`
    },
  },
}
</script>

<style scoped>
.color-light {
  background: #021821 !important;
  border: none !important;
}

.color-dark {
  background: #01212e !important;
  border: none !important;
}
</style>
