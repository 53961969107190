<template>
  <img ref="itemImg" src="" :width="width || 32" :height="height || 32" />
</template>

<script>
import api_communication from '@/api_communication'

export default {
  name: 'KopItemIcon',

  props: {
    item_name: String,
    width: String,
    height: String,
  },

  data() {
    return {
      api_communication,
    }
  },

  mounted() {
    if (this.item_name && this.item_name !== 'undefined') {
      this.$refs.itemImg.src = this.api_communication.getItemAssetURL(this.item_name)
    } else {
      this.$refs.itemImg.src = this.api_communication.getItemAssetURL('item_unk')
      this.$refs.itemImg.classList.add('unknown')
    }
    this.$refs.itemImg.dataset.itemName = this.item_name
  },
}
</script>

<style scoped>
img {
  border: 3px groove gray;
  margin-left: 3px;
  display: inline-block;
}

.unknown {
  filter: brightness(0.2);
}
</style>
