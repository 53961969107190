<template>
  <div class="card color-dark mb-4 graph">
    <div class="card-body">
      <canvas ref="chart"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto'

export default {
  name: 'FancyPointsGraph',
  props: {
    matches: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  mounted() {
    this.createChart()
  },
  methods: {
    createChart() {
      const ctx = this.$refs.chart.getContext('2d')

      const data = this.matches
        .flatMap((match, index) =>
          match.MatchData.map((player) => ({
            matchId: this.matches.length - index,
            fancyPoints: player.fancyPoints,
            ...player,
          }))
        )
        .filter((item) => item.fancyPoints > 0)
        .reverse()

      this.chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: data.map((item) => `Match ${item.matchId}`),
          datasets: [
            {
              label: 'Fancy Points',
              data: data.map((item) => item.fancyPoints),
              borderColor: '#00ff00',
              backgroundColor: 'rgba(0, 255, 0, 0.1)',
              pointRadius: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Most Recent Matches',
                color: 'rgba(255, 255, 255, 0.7)',
              },

              grid: {
                color: 'rgba(255, 255, 255, 0.1)',
              },
            },
            y: {
              title: {
                display: true,
                text: 'Fancy Points',
                color: 'rgba(255, 255, 255, 0.7)',
              },

              grid: {
                color: 'rgba(255, 255, 255, 0.1)',
              },
            },
          },
          plugins: {
            legend: {
              labels: {
                color: 'rgba(255, 255, 255, 0.7)',
              },
            },
          },
        },
      })
    },
  },
  watch: {
    matches: {
      handler() {
        if (this.chart) {
          this.chart.destroy()
        }
        this.createChart()
      },
      deep: true,
    },
  },
  beforeUnmount() {
    if (this.chart) {
      this.chart.destroy()
    }
  },
}
</script>

<style scoped>
.color-dark {
  background: #01212e !important;
  border: none !important;
}
.chart-container {
  position: relative;
  width: 50%;
  padding-bottom: 50%;
}

.card-body {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.chart-container canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
</style>
