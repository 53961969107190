<template>
  <div class="panel level-color text-shadow">
    <div class="level-fill" :style="{ width: expRequired + '%' }"></div>
    <span class="level-amount">{{ level }} </span>
  </div>
</template>

<script>
import { calculateAccountLevel } from '@/constants'

export default {
  name: 'AccountLevel',
  props: {
    xp: Number,
  },
  computed: {
    expRequired() {
      const { experiences } = calculateAccountLevel(this.xp)
      return experiences
    },
    level() {
      const { level } = calculateAccountLevel(this.xp)
      return level
    },
  },
}
</script>
<style scoped>
.panel {
  display: flex;
  border: 2px solid black;
  background-color: #0d1d2a;
  height: 30px;
  width: 148px;
  border-radius: 2px;
  padding: 2px 6px;
  font-weight: bold;
  justify-content: space-between;
  margin: 2px;
  transform: translateY(-2px);
  font-size: 1rem;
}

.level-amount {
  font-size: 1.15em;
  position: absolute;
  right: 5px;
  color: gold;
  top: 0;
}

.level-fill {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(254 255 0 / 65%);
  border-radius: 2px 0px 0px 2px;
  width: 50%;
  height: 100%;
  transition: width 4s;
  transition-delay: 6s;
  z-index: -1;
}
.text-shadow {
  text-shadow: 1.5px -1.5px 0 #000, 0 -1.5px 0 #000, 1.5px -1.5px 0 #000, 1.5px 0 0 #000, 1.5px 1.5px 0 #000,
    0 1.5px 0 #000, -1.5px 1.5px 0 #000, -1.5px 0 0 #000;
}
</style>
