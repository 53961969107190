
export const RANK_STARS_PER_MEDAL = 3
export const CALLIBRATION_GAMES = 5
export const RankedMedals = {
  BRONZE_1: 0,
  BRONZE_2: 1,
  BRONZE_3: 2,
  SILVER_1: 3,
  SILVER_2: 4,
  SILVER_3: 5,
  GOLD_1: 6,
  GOLD_2: 7,
  GOLD_3: 8,
  PLATINUM_1: 9,
  PLATINUM_2: 10,
  PLATINUM_3: 11,
  DIAMOND_1: 12,
  DIAMOND_2: 13,
  DIAMOND_3: 14,
  MYTHICAL: 15,
}

export const getRankByMmr = (mmr) => {
  const mmrPerStar = 30
  for (let i = RankedMedals.MYTHICAL; i >= 0; i--) {
    const mmrRequired = mmrPerStar * i
    if (mmr >= mmrRequired) return i
  }
  return RankedMedals.BRONZE_1
}

export const getHeroRankText = (mmr, seasonGames) => {
  const rank = getRankByMmr(mmr)
  const rankId = Math.floor(rank / RANK_STARS_PER_MEDAL)

  // Check calibration
  if (seasonGames < CALLIBRATION_GAMES) {
    return `<span> Unranked </span>`
  }

  const rankLevel = 1 + (rank % RANK_STARS_PER_MEDAL)
  let rankStars = ''
  for (let i = 0; i < rankLevel; i++) {
    rankStars += 'I'
  }

  switch (rankId) {
  case 0:
    return `<span class='bronze-medal'> Bronze ${rankStars}</span>`
  case 1:
    return `<span class='silver-medal'> Silver ${rankStars}</span>`
  case 2:
    return `<span class='gold-medal'> Gold ${rankStars}</span>`
  case 3:
    return `<span class='platinum-medal'> Platinum ${rankStars}</span>`
  case 4:
    return `<span class='diamond-medal'> Diamond ${rankStars}</span>`
  case 5:
    return `<span class='mythical-medal'> Mythical </span>`
  }
  return ''
}

export const getRankColor = (mmr) => {
  const rank = getRankByMmr(mmr)
  switch (rank) {
  case RankedMedals.BRONZE_1:
    return 'bronze-medal'
  case RankedMedals.SILVER_1:
    return 'silver-medal'
  case RankedMedals.GOLD_1:
    return 'gold-medal'
  case RankedMedals.PLATINUM_1:
    return 'platinum-medal'
  case RankedMedals.DIAMOND_1:
    return 'diamond-medal'
  case RankedMedals.MYTHICAL:
    return 'mythical-medal'
  }
}