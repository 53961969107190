<template>
  <div class="hero-stats-container bg-dark text-light p-3 border-top border-secondary">
    <div class="d-flex align-items-center">
      <HeroIcon :hero_name="heroData.heroId" width="64" height="64" class="flex-shrink-0"></HeroIcon>
      <div class="ms-3 flex-grow-1">
        <div class="row g-2 align-items-center mb-2">
          <div class="col-md-4 text-center overflow-hidden">
            <h5 class="mb-0">{{ utils.getHeroName(heroData.heroId) }}</h5>
          </div>
          <div class="col-md-4 text-center">
            <span class="badge bg-info fs-8">{{ KDA }} KDA</span>
          </div>
          <div class="col-md-4 text-center">
            <span class="badge bg-success fs-8">{{ winrate }} WR</span>
          </div>
        </div>
        <div class="row g-2 text-center specific-stats">
          <div class="col-md-4">
            <small class="d-block text-muted">Avg. CS</small>
            <span class="fs-6">{{ averageCS }}</span>
          </div>
          <div class="col-md-4">
            <small class="d-block text-muted">Avg. KDA</small>
            <span class="fs-6">{{ averageKDA }}</span>
          </div>
          <div class="col-md-4">
            <small class="d-block text-muted">Games Played</small>
            <span class="fs-6">{{ heroData.seasonGamesTotal }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import utils from '@/utils'
// import match_history from "./../assets/pretty_match_history.json";
import HeroIcon from './hero_icon.vue'

export default {
  name: 'KopMostPlayedHeros',
  props: {
    heroData: {},
  },

  components: {
    HeroIcon,
  },

  data() {
    return {
      utils,
    }
  },

  computed: {
    winrate() {
      const total = this.heroData.seasonGamesTotal
      const losses = this.heroData.seasonGamesLost
      const wins = total - losses
      return `${((wins / total) * 100).toFixed(2)}%`
    },

    KDA() {
      const KDA = (this.heroData.kills + this.heroData.assists) / this.heroData.deaths
      if (KDA == Infinity) {
        return 'Perfect'
      }
      return KDA.toFixed(2)
    },

    averageKDA() {
      const total = this.heroData.seasonGamesTotal
      const kills = this.heroData.kills
      const deaths = this.heroData.deaths
      const assists = this.heroData.assists
      return `${(kills / total).toFixed(0)} / ${(deaths / total).toFixed(0)} / ${(assists / total).toFixed(0)}`
    },

    averageCS() {
      const total = this.heroData.seasonGamesTotal
      const minions = this.heroData.minions || 1
      return `${(minions / total).toFixed(0) || 1} CS`
    },
  },
}
</script>

<style scoped>
.hero-stats-container {
  background-color: #01212e !important;
  font-size: 1rem;
  border: 1px solid;
}

.hero-stats-container .badge {
  font-weight: normal;
  padding: 0.35em 0.65em;
}

.specific-stats span {
  color: #a0a0a0 !important;
}

.border-secondary {
  border-color: hsla(197, 37%, 24%, 0.5) !important;
}

.bg-info {
  background-color: #0077be !important;
}

.bg-success {
  background-color: #00a86b !important;
}
</style>
