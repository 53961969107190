<template>
  <div>
    <div class="background-image"></div>
    <div class="container">
      <TopStatsBar></TopStatsBar>
      <navbar>
        <template #Home>
          <router-link class="nav-link" to="/">Home</router-link>
        </template>

        <template #Matches>
          <router-link class="nav-link" to="/matches">Matches</router-link>
        </template>

        <template #Heros>
          <router-link class="nav-link" to="/heros">Heros</router-link>
        </template>

        <template #Items>
          <router-link class="nav-link" to="/items">Items</router-link>
        </template>

        <template #Leaderboard>
          <router-link class="nav-link" to="/leaderboard">Leaderboard</router-link>
        </template>
      </navbar>
      <router-view></router-view>
    </div>
  </div>
  <!-- <footer2></footer2> -->
</template>

<script>
import navbar from './components/navbar.vue'
// import footer2 from './components/footer.vue';

import TopStatsBar from './components/top_stats_bar.vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/src/jquery.js'
import 'bootstrap/dist/js/bootstrap.min.js'

export default {
  name: 'App',
  components: {
    navbar,
    TopStatsBar,
    // footer2
  },
}
</script>

<style>
/* Variables for primary and secondary */

:root {
  --primary-color: hsl(192deg, 89%, 9%);
  --secondary-color: hsl(192deg, 89%, 11%);
  --primary-border-color: rgb(38, 70, 83);
}

.bg-primary {
  border: 1px solid var(--primary-border-color);
  background-color: var(--primary-color) !important;
}

.bg-secondary {
  background-color: var(--secondary-color) !important;
}

@font-face {
  src: url('./assets/fonts/pixel.ttf');
  font-family: 'Glasstown NBP';
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('./assets/background.png');
  background-size: cover;
  filter: hue-rotate(40deg) brightness(25%);
  /* Add backlight effect */
}

.router-link-active {
  color: white !important;
}

a {
  color: #d9d9d9 !important;
  text-decoration: none !important;
}

a:hover {
  color: white !important;
  filter: brightness(0.9);
}

p,
span {
  color: #d9d9d9 !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--primary-color);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(3, 31, 43);
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  margin-top: 60px;
}

.blue-team-text {
  color: rgba(100, 100, 255, 0.7);
}

.red-team-text {
  color: rgba(255, 100, 100, 0.7);
}

.bronze-medal {
  color: #ffda70 !important;
}
.silver-medal {
  color: #cce2e1 !important;
}
.gold-medal {
  color: gold !important;
}
.platinum-medal {
  color: #8cffdd !important;
}
.diamond-medal {
  color: #78d9ff !important;
}
.mythical-medal {
  color: #ff4e00 !important;
}
</style>
